import React from 'react';
import NeuroPsychoRoomJPG from '@assets/images/neuro-psychology-room.jpg';
import NeuroPsychoRoomMobileJPG from '@assets/images/neuro-psychology-room-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import NeuropsychologyMain from '~/components/organisms/NeuropsychologyMain/NeuropsychologyMain';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const NeuropsychologyPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={NeuroPsychoRoomJPG}
        mobileHero={NeuroPsychoRoomMobileJPG}
        altHero="Neuropsychologie Praxisraum"
        deLink="/neuropsychologie/"
        enLink="/en/neuropsychology/"
      >
        <NeuropsychologyMain />
      </HeaderFooterLayout>
    </>
  );
};

export default NeuropsychologyPage;
