/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
import React from 'react';
import KellerJPG from '@assets/images/keller-smaller.jpg';
import SauerJPG from '@assets/images/sauer-mobile.jpg';
import Image from '~/components/atoms/Image/Image';
import {
  ImageContainer,
  Container,
  ContainerSmall,
  FlexContainer,
  List,
} from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/neuropsychologie';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/neuropsychology';

const NeuropsychologyMain: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();

  return (
    <>
      <Container>
        <ContainerSmall>
          <div
            dangerouslySetInnerHTML={{
              __html: t('NeuroPsychContent1') || tEn('NeuroPsychContent1'),
            }}
          />
          <FlexContainer>
            <ImageContainer>
              <Image
                src={KellerJPG}
                alt="Keller Neurolopsychologie"
                width={350}
              />
            </ImageContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('NeuroPsychContent2') || tEn('NeuroPsychContent2'),
                }}
              />
            </List>
          </FlexContainer>
          <FlexContainer>
            <List>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('NeuroPsychContent3') || tEn('NeuroPsychContent3'),
                }}
              />
            </List>
            <ImageContainer>
              <Image src={SauerJPG} alt="Dr. Sauer" width={350} />
            </ImageContainer>
          </FlexContainer>
        </ContainerSmall>
      </Container>
    </>
  );
};

export default NeuropsychologyMain;
